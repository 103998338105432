import { Injectable } from '@angular/core';

import { concatMap, filter, mergeMap, type Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import type { OcpQueryData } from '@ocp/utils/types';

import { globalActions } from '@libs/core/store';
import { projectFeature } from '@libs/modules/project';

import { TAG_TREE_GROUP_KEYS, TAG_TREE_KEYS } from '../../constants';
import type {
  TTagFilterRequestEntityFields,
  TTagListRequest,
  TTagPropertyListRequest,
  TTagQueryField,
  TTagTreeFilterGroup,
  TTagTreeGroup,
} from '../../types';
import { tagTreeActions, type TTagTreeLoadChildrenSuccessResponse } from './tag-tree.actions';
import { tagTreeFeature } from './tag-tree.reducer';
import { TagApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class TagTreeEffects {
  constructor(
    private _actions$: Actions,
    private _tagApiService: TagApiService,
    private _store: Store,
  ) {}

  loadChildren$ = createEffect(() =>
    this._actions$.pipe(
      ofType(tagTreeActions.loadChildren),
      withLatestFrom(this._store.select(projectFeature.selectOpenedProject)),
      mergeMap(([{ requestId, property, filterSections, isFullUpdate }, project]) => {
        if (!project) {
          return of(tagTreeActions.loadChildrenFailure({ error: 'Project not found', requestId }));
        }

        const request$: Observable<TTagTreeLoadChildrenSuccessResponse> =
          property === 'leaf'
            ? this._tagApiService
                .getList$(project.id, this._createListPayload(filterSections))
                .pipe(map((response) => ({ type: property, data: response })))
            : this._tagApiService
                .getPropertyList$(
                  project.id,
                  this._createPropertyListPayload(property, filterSections),
                )
                .pipe(map((response) => ({ type: property, data: response })));

        return request$.pipe(
          map((response) =>
            tagTreeActions.loadChildrenSuccess({
              requestId,
              response,
              requestData: {
                property,
                filterSections,
                isFullUpdate,
              },
            }),
          ),
          catchError((error) => of(tagTreeActions.loadChildrenFailure({ error, requestId }))),
        );
      }),
    ),
  );

  loadTree$ = createEffect(() =>
    this._actions$.pipe(
      ofType(tagTreeActions.loadTree),
      withLatestFrom(
        this._store.select(projectFeature.selectOpenedProject),
        this._store.select(tagTreeFeature.selectQuery),
      ),
      switchMap(([, project, query]) => {
        const request$ = query.data[0].q
          ? this._requestChainedGroups$(project!.id, query.data)
          : this._handlePropertyRequest$(project!.id, TAG_TREE_KEYS[0]);

        return request$.pipe(
          map((response) => tagTreeActions.loadTreeSuccess({ response })),
          catchError((error) => of(tagTreeActions.loadTreeFailure({ error }))),
        );
      }),
    ),
  );

  loadByQuery$ = createEffect(() =>
    this._actions$.pipe(
      ofType(tagTreeActions.setQueryData),
      map(() => tagTreeActions.loadTree()),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => tagTreeActions.resetState()),
    ),
  );

  private _requestChainedGroups$(
    projectId: number,
    queryData: OcpQueryData<TTagQueryField>[],
  ): Observable<TTagTreeGroup[]> {
    return TAG_TREE_KEYS.reduce(
      (previousRequest$, currentKey, index) => {
        if (index === 0 && currentKey !== 'leaf') {
          return this._handlePropertyRequest$(projectId, currentKey, queryData);
        }

        if (currentKey === 'leaf') {
          return this._handleLeafBatchRequest$(previousRequest$, projectId, index, queryData);
        }

        return this._handlePropertyBatchRequest$(
          previousRequest$,
          projectId,
          currentKey,
          queryData,
          index,
        );
      },
      of([]) as Observable<TTagTreeGroup[]>,
    );
  }

  private _handlePropertyRequest$(
    projectId: number,
    currentKey: TTagTreeFilterGroup['property'],
    queryData?: OcpQueryData<TTagQueryField>[],
  ): Observable<TTagTreeGroup[]> {
    return this._tagApiService
      .getPropertyList$(projectId, this._createPropertyListPayload(currentKey, [], queryData))
      .pipe(
        map((response) =>
          response.records.map((record) => ({
            property: currentKey,
            type: 'group',
            value: record as string,
            children: [],
          })),
        ),
      );
  }

  private _handleLeafBatchRequest$(
    previousRequest$: Observable<TTagTreeGroup[]>,
    projectId: number,
    index: number,
    queryData: OcpQueryData<TTagQueryField>[],
  ): Observable<TTagTreeGroup[]> {
    return previousRequest$.pipe(
      concatMap((accumulatedResponse) =>
        this._tagApiService
          .getListBatch$(projectId, {
            requests: accumulatedResponse.flatMap((item) =>
              item.children.map((child) => {
                const filterSections: TTagTreeFilterGroup[] = TAG_TREE_GROUP_KEYS.slice(
                  0,
                  index + 1,
                ).map((key, idx) => ({
                  property: key,
                  value: (idx === 0 ? item.value : child.value) as string,
                }));
                return this._createListPayload(filterSections, queryData);
              }),
            ),
          })
          .pipe(
            map((leafResponse) => {
              this._updateChildren(accumulatedResponse, leafResponse, 1);
              return accumulatedResponse;
            }),
          ),
      ),
    );
  }

  private _handlePropertyBatchRequest$(
    previousRequest$: Observable<TTagTreeGroup[]>,
    projectId: number,
    currentKey: TTagTreeFilterGroup['property'],
    queryData: OcpQueryData<TTagQueryField>[],
    index: number,
  ): Observable<TTagTreeGroup[]> {
    return previousRequest$.pipe(
      concatMap((accumulatedResponse) =>
        this._tagApiService
          .getPropertyListBatch$(projectId, {
            requests: accumulatedResponse.map((item: any) =>
              this._createPropertyListPayload(
                currentKey,
                TAG_TREE_GROUP_KEYS.slice(0, index).map((key) => ({
                  property: key,
                  value: item.value,
                })),
                queryData,
              ),
            ),
          })
          .pipe(
            map((batchResponse) => {
              accumulatedResponse.forEach((item: any, idx: number) => {
                item.children = batchResponse.responses[idx].records.map((record: any) => ({
                  property: currentKey,
                  type: 'group',
                  value: record,
                  children: [],
                }));
              });
              return accumulatedResponse;
            }),
          ),
      ),
    );
  }

  private _updateChildren(nodes: TTagTreeGroup[], response: any, level: number): void {
    nodes.forEach((node, idx: number) => {
      if (level === TAG_TREE_KEYS.length - 1) {
        node.children =
          response.responses[idx]?.records?.map((leaf: any) => ({
            type: 'leaf',
            value: leaf,
          })) || [];
      } else {
        if (node.children.length) {
          this._updateChildren(node.children as any, response, level + 1);
        }
      }
    });
  }

  private _createPropertyListPayload(
    property: TTagTreeFilterGroup['property'],
    filterSections: TTagTreeFilterGroup[],
    queryData?: OcpQueryData<TTagQueryField>[],
  ): TTagPropertyListRequest {
    return {
      sort: {
        by: property,
        order: 'ASC',
      },
      property: {
        entityField: property,
      },
      filters: {
        query: queryData,
        filterRequest: {
          entityFields: this._createEntityFieldsFilter(filterSections),
        },
      },
    };
  }

  private _createListPayload(
    filterSections: TTagTreeFilterGroup[],
    query?: OcpQueryData<TTagQueryField>[],
  ): TTagListRequest {
    return {
      filters: {
        query: query,
        filterRequest: {
          entityFields: this._createEntityFieldsFilter(filterSections),
        },
      },
    };
    // return {
    //   pagination: {
    //     pageIndex: pagination.pageIndex,
    //     pageSize: pagination.pageSize,
    //   },
    //   sort: {
    //     by: sort.by as any,
    //     order: sort.order,
    //   },
    //   filters: {
    //     query: [
    //       {
    //         q: query.q,
    //         type: 'CONTAINS',
    //         field: query.field as any,
    //       },
    //     ],
    //   },
    // };
  }

  private _createEntityFieldsFilter(
    filterSections: TTagTreeFilterGroup[],
  ): TTagFilterRequestEntityFields {
    return filterSections.reduce((acc: any, curr: TTagTreeFilterGroup) => {
      acc![curr.property] = {
        operator: 'EQ',
        value: curr.value,
      };
      return acc;
    }, {} as TTagFilterRequestEntityFields);
  }
}
