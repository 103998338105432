import { Injectable } from '@angular/core';

import { debounceTime, filter, type Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { type ActionCreator, Store } from '@ngrx/store';

import { distinctUntilObjectChanged } from '@ocp/utils/rxjs';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions, globalFeature } from '@libs/core/store';

import type { TTagListRequest, TTagListResponse, TTagQueryField, TTagSortField } from '../types';
import { tagActions } from './tag.actions';
import { TagApiService } from '../services';
import { tagFeature } from './tag.reducer';

@Injectable({
  providedIn: 'root',
})
export class TagEffects {
  constructor(
    private _actions$: Actions,
    private _tagApiService: TagApiService,
    private _store: Store,
  ) {}

  loadTags$ = createEffect(() =>
    this._fetchTags$(
      [tagActions.setQueryData, tagActions.resetQueryFilter, tagActions.setQueryFilterDisabled],
      tagActions.loadListSuccess,
      tagActions.loadListFailure,
    ),
  );

  refreshTags$ = createEffect(() =>
    this._fetchTags$(
      [tagActions.setPageSize, tagActions.setPageIndex, tagActions.setSort],
      tagActions.refreshListSuccess,
      tagActions.refreshListFailure,
    ),
  );

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(tagActions.loadList),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(tagFeature.selectPagination),
        this._store.select(tagFeature.selectSort),
        this._store.select(tagFeature.selectQuery),
        this._store.select(tagFeature.selectRecords),
      ),
      switchMap(([, projectId, pagination, sort, query, records]) => {
        if (projectId === null || Boolean(records.length)) {
          return of();
        }
        return this._tagApiService
          .getList$(
            projectId,
            createListPayload<TTagSortField, TTagQueryField, TTagListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => tagActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(tagActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => tagActions.resetState()),
    ),
  );

  // TODO: Replace 'any' with proper types
  private _fetchTags$(
    actions: ActionCreator[],
    successCallback: (response: { result: TTagListResponse }) => void,
    errorCallback: (error: any) => any,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(tagFeature.selectPagination),
        this._store.select(tagFeature.selectSort),
        this._store.select(tagFeature.selectQuery),
      ),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([, projectId, pagination, sort, query]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._tagApiService
          .getList$(
            projectId,
            createListPayload<TTagSortField, TTagQueryField, TTagListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
          );
      }),
    );
  }
}
