import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class OcpMatPaginatorIntl extends MatPaginatorIntl {
  // Custom range label logic
  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 of ${length}`;
    }
    const startIndex = page * pageSize + 1;
    return `${startIndex} of ${length}`;
  };
}
