@let translateSection = configSig().translateSection;
@let placeholder = configSig().placeholder;

@if (configSig().selectableQueryFields.length) {
  <mat-form-field class="search-field" [ocpTranslateSection]="translateSection">
    <input
      matInput
      placeholder="{{ placeholder | ocpTranslate: queryFieldLabel() }}"
      [formControl]="configSig().queryControl"
    />
    @if (configSig().query().disabledFilters !== null) {
      <ocp-button-universal [config]="disableFiltersButton()"></ocp-button-universal>
    }
    <ocp-actions-menu matSuffix [config]="menuConfigSig()"></ocp-actions-menu>
  </mat-form-field>
} @else {
  <mat-form-field class="search-field" [ocpTranslateSection]="translateSection">
    <input
      matInput
      placeholder="{{ placeholder | ocpTranslate: queryFieldLabel() }}"
      [formControl]="configSig().queryControl"
  /></mat-form-field>
}
