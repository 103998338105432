@let searchConfig = configSig().searchConfig;
@let tableConfig = configSig().tableConfig;
@let paginationConfig = configSig().paginationConfig;

<div class="table-header" [ocpTranslateSection]="tableConfig.translateSection || 'TABLE'">
  @if (tableConfig.titleKey) {
    <h3 class="ocp-title-primary ocp-widget-title">
      {{ tableConfig.titleKey | ocpTranslate }}
    </h3>
  }

  @if (searchConfig) {
    <ocp-search [config]="searchConfig"></ocp-search>
  }

  <ng-content select="table-actions"></ng-content>
</div>
@if (paginationConfig?.type === 'scroll') {
  <!--NOTE: Be careful with infiniteScrollContainer value-->
  <ocp-table-basic
    [config]="tableConfig"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="0"
    (scrolled)="handleInfiniteScroll()"
    [scrollWindow]="false"
    [infiniteScrollContainer]="'.table-container'"
  ></ocp-table-basic>
} @else {
  <ocp-table-basic [config]="tableConfig"></ocp-table-basic>
}

@if (paginationConfig?.type === 'standard') {
  <ocp-paginator-standard
    [config]="castPaginatorConfig(paginationConfig!)"
  ></ocp-paginator-standard>
}
