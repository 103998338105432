import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  HostBinding,
  input,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

import { OcpSearchComponent } from '@ocp/ui-kit/search';
import { OcpPaginatorStandardComponent, OcpPaginatorStandardConfig } from '@ocp/ui-kit/paginator';
import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';

import { OcpTableBasicComponent } from '../table-basic';
import type { OcpTableFeaturedConfig, OcpTableFeaturePaginator } from './types';

@Component({
  selector: 'ocp-table-featured',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './table-featured.component.html',
  styleUrl: './table-featured.component.scss',
  imports: [
    MatTableModule,
    OcpTableBasicComponent,
    OcpPaginatorStandardComponent,
    InfiniteScrollDirective,
    OcpSearchComponent,
    OcpTranslationSectionDirective,
    OcpTranslatePipe,
  ],
})
export class OcpTableFeaturedComponent<
  TEntity,
  TColumnDef extends string,
  TQueryField extends string,
  TSortField extends string,
> implements AfterViewInit
{
  @HostBinding('class.ocp-table-featured') hostCssClass = true;

  inputConfigSig = input.required<
    OcpTableFeaturedConfig<TEntity, TColumnDef, TQueryField, TSortField>
  >({ alias: 'config' });

  configSig = computed(() => {
    const inputConf = this.inputConfigSig();

    return {
      ...inputConf,
      tableConfig: {
        ...inputConf.tableConfig,
        data: computed(() => this._convertDataToDatasource(inputConf.tableConfig.data())),
      },
    };
  });

  @ViewChild(MatPaginator) paginatorRef: MatPaginator | null = null;

  constructor() {
    effect(() => {
      const source = this.configSig().tableConfig.data();
      this._attachPaginator(source);
    });
  }

  public ngAfterViewInit(): void {
    const source = this.configSig().tableConfig.data();
    this._attachPaginator(source);
  }

  public handleInfiniteScroll(): void {
    const { paginationConfig } = this.configSig();
    if (paginationConfig?.type !== 'scroll') {
      return;
    }

    const { pagination, onPaginationChange } = paginationConfig.config;

    const pageIndex = pagination().pageIndex;

    if (pageIndex >= pagination().totalPages - 1) {
      return;
    }

    onPaginationChange?.({
      type: 'pageIndex',
      value: pageIndex + 1,
      behavior: 'add',
    });
  }

  // TODO: #HOTFIX fix the problem correctly
  public castPaginatorConfig(
    paginationConfig: OcpTableFeaturePaginator,
  ): OcpPaginatorStandardConfig {
    return paginationConfig.config as unknown as OcpPaginatorStandardConfig;
  }

  private _attachPaginator(source: MatTableDataSource<TEntity>): void {
    if (this.configSig().paginationConfig == null || !this.paginatorRef) {
      return;
    }
    source.paginator = this.paginatorRef;
  }

  private _convertDataToDatasource(
    data: MatTableDataSource<TEntity> | TEntity[],
  ): MatTableDataSource<TEntity> {
    if (data instanceof MatTableDataSource) {
      return data as MatTableDataSource<TEntity>;
    }
    return new MatTableDataSource(data);
  }
}
