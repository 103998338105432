import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { TAG_TREE_KEYS_MAP } from '../../constants';
import type {
  TTagListResponse,
  TTagPropertyListResponse,
  TTagQueryField,
  TTagSortField,
  TTagTreeFilterGroup,
  TTagTreeGroup,
  TTagTreeGroupKey,
  TTagTreeKey,
} from '../../types';
import { TAG_TREE_STORE_OPTIONS } from './tag-tree-store-options.constant';

type TLoadChildrenRequest = {
  property: TTagTreeKey;
  filterSections: TTagTreeFilterGroup[];
  isFullUpdate: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
type withRequestId<T> = T & {
  requestId: string;
};

export type TTagTreeLoadChildrenSuccessResponse =
  | {
      type: TTagTreeGroupKey;
      data: TTagPropertyListResponse<any>;
    }
  | {
      type: typeof TAG_TREE_KEYS_MAP.leaf;
      data: TTagListResponse;
    };

export const tagTreeActions = createActionGroup({
  source: 'TagTree',
  events: {
    ...getEntityListActions<TTagTreeGroup, TTagQueryField, TTagSortField>()(TAG_TREE_STORE_OPTIONS),
    'Load Tree': emptyProps(),
    'Load Tree Success': props<{ response: TTagTreeGroup[] }>(),
    'Load Tree Failure': props<{ error: any }>(),
    'Load Children': props<withRequestId<TLoadChildrenRequest>>(),
    'Load Children Success': props<
      withRequestId<{
        response: TTagTreeLoadChildrenSuccessResponse;
        requestData: TLoadChildrenRequest;
      }>
    >(),
    'Load Children Failure': props<withRequestId<{ error: any }>>(),
    'Reset State': emptyProps(),
  },
});
