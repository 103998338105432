export const OCP_QUERY_TYPES = ['CONTAINS', 'EXACT', 'ENDS_WITH', 'STARTS_WITH'] as const;

export type OcpQueryType = (typeof OCP_QUERY_TYPES)[number];

export type OcpQueryData<TField extends string = string> = {
  field: TField;
  q: string;
  type: OcpQueryType;
};

export type OcpQuery<TField extends string = string> = {
  data: OcpQueryData<TField>[];
  disabledFilters: OcpQueryData<TField>[] | null;
  isDisabledFilterActive: boolean;
};
