import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  HostBinding,
  input,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginator, MatPaginatorIntl, type PageEvent } from '@angular/material/paginator';

import { Subject } from 'rxjs';

import { matPaginatorIntlFactory } from './mat-paginator-intl-factory';
import { type OcpPaginatorStandardConfig } from './models';

@Component({
  selector: 'ocp-paginator-standard',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './paginator-standard.component.html',
  styleUrl: './paginator-standard.component.scss',
  imports: [MatPaginator],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntl }],
})
export class OcpPaginatorStandardComponent implements OnInit {
  @HostBinding('class.ocp-paginator-standard') hostCssClass = true;

  configSig = input.required<OcpPaginatorStandardConfig>({
    alias: 'config',
  });

  private _pageChange$ = new Subject<PageEvent>();

  constructor(
    private readonly _destroyRef: DestroyRef,
    private readonly _matPaginatorIntl: MatPaginatorIntl,
  ) {
    effect(() => {
      this._applyPaginatorIntl(this.configSig().useCustomLabel);
    });
  }

  public ngOnInit(): void {
    this._pageChange$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      const { pagination, onPaginationChange } = this.configSig();
      const { pageIndex, pageSize } = pagination();

      if (pageSize !== event.pageSize) {
        onPaginationChange?.({
          type: 'pageSize',
          value: event.pageSize,
          behavior: 'replace',
        });
      }

      if (pageIndex !== event.pageIndex) {
        onPaginationChange?.({
          type: 'pageIndex',
          value: event.pageIndex,
          behavior: 'replace',
        });
      }
    });
  }

  public handlePageChange(event: PageEvent): void {
    this._pageChange$.next(event);
  }

  private _applyPaginatorIntl(isCustom = false): void {
    const customPaginatorIntl = matPaginatorIntlFactory(isCustom);
    Object.assign(this._matPaginatorIntl, customPaginatorIntl);
    this._matPaginatorIntl.changes.next(); // Force refresh
  }
}
