import { type TTagTreeTransformFns } from '../../types';

export const TAG_TREE_TRANSFORM_FNS: TTagTreeTransformFns = {
  equipmentType: (entities) =>
    entities.map((el) => ({
      id: el.value,
      expandable: true,
      name: el.value,
      type: el.property,
    })),
  name: (entities) =>
    entities.map((el) => ({
      id: el.value,
      expandable: true,
      name: el.value,
      type: el.property,
    })),
  leaf: (entities) =>
    entities.map((el) => ({
      id: `${el.value.id}`,
      expandable: false,
      name: el.value.name,
      type: el.type,
    })),
};
