import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  HostBinding,
  input,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';

import { switchMap } from 'rxjs';

import { type OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpIconBasicComponent } from '@ocp/ui-kit/icon';
import {
  type OcpTranslateInterpolationParam,
  OcpTranslatePipe,
  OcpTranslationSectionDirective,
} from '@ocp/fusion-cdk/translate';
import { OCP_QUERY_TYPES, type OcpQueryType } from '@ocp/utils/types';
import { OcpActionMenuConfig, OcpActionsMenuComponent } from '@ocp/ui-kit/menu';

import { type OcpSearchConfig } from './models';

@Component({
  selector: 'ocp-search',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    OcpIconBasicComponent,
    OcpTranslatePipe,
    OcpTranslationSectionDirective,
    OcpButtonUniversalComponent,
    MatCheckbox,
    OcpActionsMenuComponent,
  ],
})
export class OcpSearchComponent<TQueryField extends string> {
  @HostBinding('class.ocp-search') hostCssClass = true;

  configSig = input.required<OcpSearchConfig<TQueryField>>({ alias: 'config' });

  menuConfigSig = computed(() => {
    return new OcpActionMenuConfig({
      triggerIcon: this.configSig().settingsIcon,
      position: { x: 'before', y: 'below' },
      menuItems: [
        {
          item: {
            type: 'group',
            groupName: 'SEARCH_TYPE_TITLE',
            items: OCP_QUERY_TYPES.map((type) => {
              return {
                type: 'checkbox',
                label: type,
                callback: () => this.handleMenuSelection(type),
                disabled: computed(() => this.configSig().query().isDisabledFilterActive),
                classNames: ['search-menu-button'],
                classNamesDynamic: computed(() => [
                  `${
                    this.configSig()
                      .query()
                      .data.some((el) => el.type === type)
                      ? 'selected'
                      : ''
                  }`,
                ]),
                checked: computed(() =>
                  this.configSig()
                    .query()
                    .data.some((el) => el.type === type),
                ),
              };
            }),
          },
        },
        {
          item: {
            type: 'group',
            groupName: 'QUERY_FIELDS_TITLE',
            items: this.configSig().selectableQueryFields.map((field) => {
              return {
                type: 'checkbox',
                label: field.labelKey,
                checked: computed(() =>
                  this.configSig()
                    .query()
                    .data.some((el) => el.field === field.value),
                ),
                disabled: computed(() => this.configSig().query().isDisabledFilterActive),
                classNames: ['search-menu-button'],
                classNamesDynamic: computed(() => [
                  `${
                    this.configSig()
                      .query()
                      .data.some((el) => el.field === field.value)
                      ? 'selected'
                      : ''
                  }`,
                ]),
                callback: () => this.toggleFieldSelection(field.value),
              };
            }),
          },
        },
        {
          item: {
            type: 'group',
            groupName: '',
            items: [
              {
                type: 'button',
                actionsConfig: {
                  callback: () => untracked(this.configSig).onResetFilter?.(),
                },
                visualisationConfig: {
                  label: 'CLEAR_FILTERS',
                  appearance: 'menu',
                  classNames: ['search-menu-button'],
                },
              },
            ],
          },
        },
      ],
    });
  });

  queryFieldLabel = computed<OcpTranslateInterpolationParam | undefined>(() => {
    const paramKey = this.configSig().selectableQueryFields.find(
      (field) => field.value === this.configSig().query().data[0].field,
    )?.labelKey;

    if (paramKey == null) {
      return undefined;
    }

    return {
      value: {
        value: paramKey,
      },
    };
  });

  disableFiltersButton = computed<OcpButtonRealisation>(() => ({
    actionsConfig: {
      callback: () => {
        const { onToggleFilterDisableState, query } = untracked(this.configSig);
        const { isDisabledFilterActive } = untracked(query);
        onToggleFilterDisableState?.(!isDisabledFilterActive);
      },
    },
    visualisationConfig: {
      appearance: 'icon',
      icon: {
        icon: 'filter_list_off',
      },
      classNamesDynamic: computed(() => [
        this.configSig().query().isDisabledFilterActive ? 'primary' : '',
      ]),
      tooltip: 'DISABLE_FILTERS',
    },
  }));

  constructor() {
    toObservable(this.configSig)
      .pipe(
        switchMap((config) => config.queryControl.valueChanges),
        takeUntilDestroyed(),
      )
      .subscribe((value) => {
        const { query, onQueryDataChange } = this.configSig();
        onQueryDataChange?.(query().data.map((el) => ({ ...el, q: value })));
      });

    effect(() => {
      const { queryControl, query } = this.configSig();
      const queryDataQ = untracked(() => query().data[0].q);
      if (!queryControl.value && queryDataQ) {
        queryControl.setValue(queryDataQ, {
          emitEvent: false,
        });
      }
    });
  }

  public handleMenuSelection(value: OcpQueryType): void {
    const { query, onQueryDataChange } = this.configSig();
    const queryData = query().data;

    if (queryData.some((el) => el.type === value)) {
      return;
    }

    onQueryDataChange?.(
      queryData.map((el) => ({
        ...el,
        type: value,
      })),
    );
  }

  public toggleFieldSelection(field: TQueryField): void {
    const { query, onQueryDataChange } = this.configSig();
    const queryData = query().data;
    const isDeselect = queryData.some((el) => el.field === field);

    if (isDeselect && queryData.length === 1) {
      return;
    }

    const updatedQueryData = isDeselect
      ? queryData.filter((el) => el.field !== field)
      : [...queryData, { ...queryData[0], field }];

    onQueryDataChange?.(updatedQueryData);
  }
}
