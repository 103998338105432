@let pagination = configSig().pagination();

<mat-paginator
  class="ocp-paginator-standard mat-paginator-sticky"
  [class.hide-page-size]="configSig().hidePageSize"
  [pageSize]="pagination.pageSize"
  [pageSizeOptions]="configSig().pageSizeOptions"
  [pageIndex]="pagination.pageIndex"
  [length]="pagination.totalRecords"
  [showFirstLastButtons]="configSig().showFirstLastButtons"
  (page)="handlePageChange($event)"
></mat-paginator>
