import { OCP_DEFAULT_PAGINATION_CONFIG } from '@ocp/utils/constants';
import type { OcpRequiredAndPartial } from '@ocp/utils/types';

import { OcpPaginatorConfig, type OcpPaginatorConfigProps } from '../../models';

type OcpPaginatorStandardConfigProps = OcpPaginatorConfigProps &
  OcpRequiredAndPartial<
    OcpPaginatorStandardConfig,
    never,
    'hidePageSize' | 'pageSizeOptions' | 'showFirstLastButtons' | 'useCustomLabel'
  >;

export class OcpPaginatorStandardConfig extends OcpPaginatorConfig {
  pageSizeOptions: number[] = OCP_DEFAULT_PAGINATION_CONFIG.pageSizeOptions;
  showFirstLastButtons = true;
  hidePageSize = false;
  useCustomLabel = false;

  constructor(props: OcpPaginatorStandardConfigProps) {
    super(props);
    Object.assign(this, props);
  }
}
