import { createFeature, createReducer, on } from '@ngrx/store';

import { getDefaultStoreEntityList } from '@ocp/utils-advanced/constants';
import { getEntityListHandlers } from '@ocp/utils-advanced/ngrx';
import type { OcpStoreEntityList } from '@ocp/utils-advanced/types';
import type { OcpQueryData } from '@ocp/utils/types';

import { TAG_STORE_OPTIONS } from './tag-store-options.constant';
import { tagActions } from './tag.actions';
import type { TTag, TTagQueryField, TTagSortField } from '../types';

type TInitialState = OcpStoreEntityList<
  TTag,
  typeof TAG_STORE_OPTIONS,
  TTagQueryField,
  TTagSortField
>;

const defaultQueryData: OcpQueryData<TTagQueryField>[] = [
  {
    type: 'CONTAINS',
    field: 'name',
    q: '',
  },
];

const defaultState = getDefaultStoreEntityList<TTag, TTagQueryField, TTagSortField>()(
  TAG_STORE_OPTIONS,
);

const initialState: TInitialState = {
  ...defaultState,
  query: {
    ...defaultState.query,
    data: defaultQueryData,
  },
  sort: { by: 'name', order: 'ASC' },
};

const defaultHandlers = getEntityListHandlers<TTag, object, TTagQueryField, TTagSortField>()(
  TAG_STORE_OPTIONS,
);

const tagReducer = createReducer(
  initialState,
  on(tagActions.loadList, (state) => defaultHandlers.loadList(state)),
  on(tagActions.loadListSuccess, (state, { result: response }) =>
    defaultHandlers.loadListSuccess(state, response),
  ),
  on(tagActions.loadListFailure, (state, { error }) =>
    defaultHandlers.loadListFailure(state, error),
  ),
  on(tagActions.refreshListSuccess, (state, { result: response }) =>
    defaultHandlers.refreshListSuccess(state, response),
  ),
  on(tagActions.refreshListFailure, (state, { error }) =>
    defaultHandlers.refreshListFailure(state, error),
  ),
  on(tagActions.setPageSize, (state, { pageSize, refreshBehavior }) =>
    defaultHandlers.setPageSize(state, pageSize, refreshBehavior),
  ),
  on(tagActions.setPageIndex, (state, { pageIndex, refreshBehavior }) =>
    defaultHandlers.setPageIndex(state, pageIndex, refreshBehavior),
  ),
  on(tagActions.setSort, (state, { sort, refreshBehavior }) =>
    defaultHandlers.setSort(state, sort, refreshBehavior),
  ),
  on(tagActions.setQueryData, (state, { queryData }) =>
    defaultHandlers.setQueryData(state, queryData, defaultQueryData),
  ),
  on(tagActions.setFilterItem, (state, { item }) => defaultHandlers.setFilterItem(state, item)),
  on(tagActions.setFilterItemDisabled, (state, { isDisabled }) =>
    defaultHandlers.setFilterItemDisabled(state, isDisabled),
  ),
  on(tagActions.resetState, () => initialState),
  on(tagActions.resetQueryFilter, (state) =>
    defaultHandlers.resetQueryFilter(state, defaultQueryData),
  ),
  on(tagActions.setQueryFilterDisabled, (state, { isDisabled }) =>
    defaultHandlers.setQueryFilterDisabled(state, isDisabled, defaultQueryData),
  ),
);

export const tagFeature = createFeature({
  name: 'tag',
  reducer: tagReducer,
});
