import { createActionGroup } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { TAG_STORE_OPTIONS } from './tag-store-options.constant';
import type { TTag, TTagQueryField, TTagSortField } from '../types';

export const tagActions = createActionGroup({
  source: 'Tag',
  events: {
    ...getEntityListActions<TTag, TTagQueryField, TTagSortField>()(TAG_STORE_OPTIONS),
  },
});
