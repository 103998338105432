import type { OcpExactKeys } from '@ocp/utils/types';

import type { TTagRequired } from '../types';

export const TAG_KEYS_MAP: OcpExactKeys<TTagRequired> = {
  id: 'id',
  name: 'name',
  className: 'className',
  equipmentType: 'equipmentType',
};
