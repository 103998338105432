import type { TTagTreeGroup, TTagTreeKey, TTagTreeLeaf } from '../../types';

export const mapTagResponseToTree = (
  property: TTagTreeKey,
  records: any[],
): TTagTreeGroup[] | TTagTreeLeaf[] => {
  if (property === 'leaf') {
    return records.map((el) => ({
      type: 'leaf',
      value: el,
    }));
  }
  return records.map((el) => ({
    type: 'group',
    property: property,
    value: el,
    children: [],
  }));
};
