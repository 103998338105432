import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from './app.actions';

type TAppState = {
  sidenavOpened: boolean;
  splitDocuments: boolean;
};

const initialState: TAppState = {
  sidenavOpened: true,
  splitDocuments: false,
};

const appReducer = createReducer(
  initialState,
  on(appActions.setSidenavOpened, (state, { isOpened }) => ({
    ...state,
    sidenavOpened: isOpened,
  })),
  on(appActions.setSplitDocuments, (state, { isActive }) => ({
    ...state,
    splitDocuments: isActive,
  })),
);

export const appFeature = createFeature({
  name: 'App',
  reducer: appReducer,
});
