import { signal, type Signal } from '@angular/core';

import { OCP_DEFAULT_PAGINATION_CONFIG } from '@ocp/utils/constants';
import type { OcpPaginationData, OcpRequiredAndPartial } from '@ocp/utils/types';

import { type OcpPaginationEvent } from '../types';

export type OcpPaginatorConfigProps = OcpRequiredAndPartial<
  OcpPaginatorConfig,
  never,
  'onPaginationChange' | 'pagination'
>;

// Base class for paginator configs
export class OcpPaginatorConfig {
  pagination: Signal<OcpPaginationData> = signal(OCP_DEFAULT_PAGINATION_CONFIG);

  onPaginationChange?: (event: OcpPaginationEvent) => void;

  constructor(props: OcpPaginatorConfigProps) {
    Object.assign(this, props);
  }
}
