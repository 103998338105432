import { Injectable } from '@angular/core';

import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import type { OcpTreeDynamicNodeInitialData } from '@ocp/ui-kit/tree';

import type {
  TTagTreeFilterGroup,
  TTagTreeKey,
  TTagTreeLoadChildrenFns,
  TTagTreeMapping,
} from '../types';
import { mapTagResponseToTree, TAG_TREE_TRANSFORM_FNS } from '../constants';
import { tagTreeActions, tagTreeFeature } from '../store';

@Injectable({
  providedIn: 'root',
})
export class TagTreeService {
  private readonly _loadChildrenFns: TTagTreeLoadChildrenFns;

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {
    this._loadChildrenFns = {
      equipmentType: (nodeId, parents) => {
        return this._loadTreeData$('name', [
          ...parents.map((el) => ({ value: el.id, property: el.type }) as TTagTreeFilterGroup),
          {
            property: 'equipmentType',
            value: nodeId,
          },
        ]);
      },
      name: (nodeId, parents) => {
        return this._loadTreeData$('leaf', [
          ...parents.map((el) => ({ value: el.id, property: el.type }) as TTagTreeFilterGroup),
          {
            property: 'name',
            value: nodeId,
          },
        ]);
      },

      leaf: () => of([]),
    };
  }

  public startChildrenLoading$(
    [nodeId, parents]: Parameters<TTagTreeLoadChildrenFns[keyof TTagTreeMapping]>,
    childType: TTagTreeKey,
  ): ReturnType<TTagTreeLoadChildrenFns[keyof TTagTreeMapping]> {
    return this._loadChildrenFns[`${childType}`](nodeId, parents);
  }

  private _loadTreeData$(
    childType: TTagTreeKey,
    filterSections: TTagTreeFilterGroup[],
  ): Observable<OcpTreeDynamicNodeInitialData[]> {
    return this._store.select(tagTreeFeature.selectQuery).pipe(
      first(),
      switchMap((query) => {
        if (query.data[0].q) {
          return this._selectTreeData$(childType, filterSections);
        } else {
          return this._triggerFetchingTreeData$(childType, filterSections);
        }
      }),
    );
  }

  private _selectTreeData$(
    childType: TTagTreeKey,
    filterSections: TTagTreeFilterGroup[],
  ): Observable<OcpTreeDynamicNodeInitialData[]> {
    return this._store.select(tagTreeFeature.selectChildren(filterSections)).pipe(
      first(),
      map((data) => TAG_TREE_TRANSFORM_FNS[`${childType}`](data as any)),
    );
  }

  private _triggerFetchingTreeData$(
    childType: TTagTreeKey,
    filterSections: TTagTreeFilterGroup[],
  ): Observable<OcpTreeDynamicNodeInitialData[]> {
    const requestId = crypto.randomUUID();

    const dispatchAction$ = of(null).pipe(
      tap(() => {
        this._store.dispatch(
          tagTreeActions.loadChildren({
            requestId,
            filterSections,
            isFullUpdate: false,
            property: childType,
          }),
        );
      }),
    );

    return forkJoin([this._listenForLoadChildrenResponse$(requestId), dispatchAction$]).pipe(
      map(([resp]) => {
        return resp.success
          ? TAG_TREE_TRANSFORM_FNS[`${childType}`](
              mapTagResponseToTree(childType, resp.records) as any[],
            )
          : [];
      }),
    );
  }

  private _listenForLoadChildrenResponse$(
    requestId: string,
  ): Observable<{ success: boolean; records: any[]; error?: string }> {
    return this._actions$.pipe(
      ofType(tagTreeActions.loadChildrenSuccess, tagTreeActions.loadChildrenFailure),
      filter((action) => action.requestId === requestId),
      first(), // Only take the first match and complete
      map((action) => {
        if (action.type === tagTreeActions.loadChildrenSuccess.type) {
          return { success: true, records: action.response.data.records };
        } else {
          return { success: false, records: [], error: action.error };
        }
      }),
    );
  }
}
