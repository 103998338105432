import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const appActions = createActionGroup({
  source: 'App',
  events: {
    'Set Sidenav Opened': props<{ isOpened: boolean }>(),
    // TODO: Come up with better name for this action
    'Set Split Documents': props<{ isActive: boolean }>(),
    'Refresh Data': emptyProps(),
  },
});
