import { type Signal } from '@angular/core';
import { FormControl } from '@angular/forms';

import type { OcpIconBasic } from '@ocp/ui-kit/icon';
import type { OcpQuery, OcpQueryData, OcpRequiredAndPartial } from '@ocp/utils/types';

type OcpQueryField<TFiled> = {
  labelKey: string;
  value: TFiled;
};

type OcpSearchConfigProps<TQueryField extends string = string> = OcpRequiredAndPartial<
  OcpSearchConfig<TQueryField>,
  'query',
  | 'onQueryDataChange'
  | 'onResetFilter'
  | 'onToggleFilterDisableState'
  | 'placeholder'
  | 'queryControl'
  | 'selectableQueryFields'
  | 'settingsIcon'
  | 'translateSection'
>;

export class OcpSearchConfig<TQueryField extends string = string> {
  query!: Signal<OcpQuery<TQueryField>>;

  selectableQueryFields: OcpQueryField<TQueryField>[] = [];
  placeholder = '';
  translateSection = '';
  queryControl = new FormControl<string>('', { nonNullable: true });
  settingsIcon: OcpIconBasic = {
    icon: 'search',
  };
  onQueryDataChange?: (queryData: OcpQueryData<TQueryField>[]) => void;
  onResetFilter?: () => void;
  onToggleFilterDisableState?: (isDisabled: boolean) => void;

  constructor(props: OcpSearchConfigProps<TQueryField>) {
    Object.assign(this, props);
  }
}
