import { MatPaginatorIntl } from '@angular/material/paginator';

import { OcpMatPaginatorIntl } from './services';

export function matPaginatorIntlFactory(useCustomLabel: boolean): MatPaginatorIntl {
  if (useCustomLabel) {
    return new OcpMatPaginatorIntl();
  } else {
    return new MatPaginatorIntl();
  }
}
