import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { getDefaultStoreEntityList } from '@ocp/utils-advanced/constants';
import { getEntityListHandlers } from '@ocp/utils-advanced/ngrx';
import type { OcpStoreEntityList } from '@ocp/utils-advanced/types';
import type { OcpQueryData } from '@ocp/utils/types';

import { mapTagResponseToTree } from '../../constants';
import type {
  TTagListResponse,
  TTagPropertyListResponse,
  TTagQueryField,
  TTagSortField,
  TTagTreeFilterGroup,
  TTagTreeGroup,
  TTagTreeKey,
} from '../../types';
import { TAG_TREE_STORE_OPTIONS } from './tag-tree-store-options.constant';
import { tagTreeActions } from './tag-tree.actions';

type TStateExtend = {
  isFullUpdate: boolean;
};

type TInitialState = OcpStoreEntityList<
  TTagTreeGroup,
  typeof TAG_TREE_STORE_OPTIONS,
  TTagQueryField,
  TTagSortField
> &
  TStateExtend;

const defaultQueryData: OcpQueryData<TTagQueryField>[] = [
  {
    type: 'CONTAINS',
    field: 'name',
    q: '',
  },
];

const defaultState = getDefaultStoreEntityList<TTagTreeGroup, TTagQueryField, TTagSortField>()(
  TAG_TREE_STORE_OPTIONS,
);

const initialState: TInitialState = {
  ...defaultState,
  query: {
    ...defaultState.query,
    data: defaultQueryData,
  },
  sort: { by: 'name', order: 'ASC' },
  isFullUpdate: true,
};

const defaultHandlers = getEntityListHandlers<
  TTagTreeGroup,
  TStateExtend,
  TTagQueryField,
  TTagSortField
>()(TAG_TREE_STORE_OPTIONS);

const tagTreeReducer = createReducer(
  initialState,
  on(tagTreeActions.loadTree, (state) => ({
    ...state,
    records: [],
    loadingInitial: true,
    isFullUpdate: true,
  })),
  on(tagTreeActions.loadTreeSuccess, (state, { response }) => {
    return {
      ...state,
      records: response,
      loadingInitial: false,
    };
  }),
  // TODO: #TBD Loading state is handled by UI tree now
  // on(documentTreeActions.loadChildren, (state) => {
  //   return state;
  // }),
  on(tagTreeActions.loadChildrenSuccess, (state, { response, requestData }) => {
    // TODO: #LOW Change implementation, remove mutation inside
    const tree = JSON.parse(JSON.stringify(state.records));
    return {
      ...state,
      records: addChildrenToTree(
        tree,
        requestData.filterSections,
        requestData.property,
        response.data,
      ),
      isFullUpdate: requestData.isFullUpdate,
    };
  }),
  on(tagTreeActions.setQueryData, (state, { queryData }) =>
    defaultHandlers.setQueryData(state, queryData, defaultQueryData),
  ),
  on(tagTreeActions.resetState, () => initialState),
);

export const tagTreeFeature = createFeature({
  name: 'tagTree',
  reducer: tagTreeReducer,
  extraSelectors: ({ selectRecords, selectIsFullUpdate }) => {
    const selectChildren = (parentSections: TTagTreeFilterGroup[]) =>
      createSelector(selectRecords, (records) => {
        const node = findNode(records, parentSections);
        return node?.children ? [...node.children] : [];
      });

    const selectTreeDataIfFullUpdate = createSelector(
      selectRecords,
      selectIsFullUpdate,
      (records, isFullUpdate): TTagTreeGroup[] | null => (isFullUpdate ? records : null),
    );

    return {
      selectChildren,
      selectTreeDataIfFullUpdate,
    };
  },
});

function findNode(
  tree: TTagTreeGroup[],
  filterSections: TTagTreeFilterGroup[],
): TTagTreeGroup | null {
  // Initialize a stack with the root nodes and starting filter index
  const stack: { node: TTagTreeGroup; filterIndex: number }[] = tree.map((node) => ({
    node,
    filterIndex: 0,
  }));

  while (stack.length > 0) {
    const { node, filterIndex } = stack.pop()!;

    // Check if the current node matches the filter at filterIndex
    const currentFilter = filterSections[filterIndex];
    if (node.property === currentFilter.property && node.value === currentFilter.value) {
      // If this is the last filter, return the node as we've found the target
      if (filterIndex === filterSections.length - 1) {
        return node;
      }

      // Otherwise, add children to the stack with the next filter index
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (node.type === 'group' && node.children) {
        for (const child of node.children as TTagTreeGroup[]) {
          stack.push({ node: child, filterIndex: filterIndex + 1 });
        }
      }
    }
  }

  // If no matching node was found, return null
  return null;
}

function addChildrenToTree(
  tree: TTagTreeGroup[],
  filterSections: TTagTreeFilterGroup[],
  property: TTagTreeKey,
  response: TTagListResponse | TTagPropertyListResponse<TTagTreeFilterGroup['property']>,
): TTagTreeGroup[] {
  if (filterSections.length === 0) {
    return [...tree, ...mapTagResponseToTree(property, response.records)] as any[];
  }

  // Destructure the first filter section
  const [currentFilter, ...remainingFilters] = filterSections;

  // Traverse through the current level of the tree to find a matching node
  return tree.map((node) => {
    if (node.property === currentFilter.property && node.value === currentFilter.value) {
      // Recursively add children to the matching node

      return {
        ...node,
        children: addChildrenToTree(
          node.children.length ? [...(node.children as any)] : [],
          remainingFilters,
          property,
          response,
        ),
      } as any;
    }
    return node;
  });
}
